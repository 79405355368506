.commPadding {
  padding: 16px;
  background-color: #FFFFFF;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
}

.height {
  height: 93%;
  margin-top: 20px;
}

.left {
  width: 280px;

  .list {
    width: 248px;
    border: 1px solid #D5EAFF;
    padding: 16px;
    margin: 0 auto 16px;
  }

  .text {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #213333;
  }

  .number {
    font-size: 20px;
    color: #4896FF;
    font-weight: bold;
  }
}

.subjectTab {
  margin-left: 16px;
  width: calc(100% - 296px);

  .tableHead {
    font-size: 16px;
    font-weight: 500;
    color: #213333;
    margin-bottom: 12px;
  }

  .record-table {
    margin-bottom: 12px;

    .experiment {
      color: #4F9AFE;
    }

    .production {
      color: #09BEE9;
    }

    .el-button--default {
      height: 24px;
      line-height: 24px;
      padding: 0 15px;
    }

    .el-button--text {
      color: #FFAB57;
    }

    .suspended {
      border-color: #BBBBBB;
      color: #BBBBBB;
      background: rgba(187, 187, 187, 0.1);
    }

    .experimentCo {
      border-color: #4F9AFE;
      color: #4F9AFE;
      background: rgba(79, 154, 254, 0.1);
    }

    .approval {
      border-color: #FF7B57;
      color: #FF7B57;
      background: rgba(255, 123, 87, 0.1);
    }
  }

}

.payBtn {
  width: 248px;
  height: 40px;
  background: #4F9AFE;
  color: #FFFFFF;
  border: none;
}

.cageInfo {
  line-height: 2;
  font-weight: 400;
  color: #333333;
  font-size: 14px;

  .title {
    font-weight: bold;
    color: #4F9AFE;
  }

  .info {
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    padding-bottom: 10px;
    margin-bottom: 10px;

    span {
      display: inline-block;
      min-width: 215px;

      label {
        color: #4F9AFE;
      }
    }
  }

  .state {
    color: #FFAB57;
  }
}

.qrcode {
  font-size: 12px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #999999;
  text-align: center;
  width: 120px;
  margin-top: 10px;

  .codeImg {
    width: 120px;
    height: 120px;
    border: 1px solid #BBBBBB;
    padding: 10px;
  }

}

.payType ::v-deep {
  .el-radio {
    width: 142px;
    height: 32px;
    border: 1px solid #BBBBBB;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    //justify-content: center;
    &.is-disabled.is-checked {
      border-color: #4F9AFE;
    }

    .el-radio__label img {
      margin-right: 5px;
    }
  }

  .el-radio__input.is-disabled.is-checked + span.el-radio__label {
    color: #4F9AFE;
  }

  .el-radio__inner {
    border-color: #BBBBBB;
    background-color: #FFFFFF;
  }

  .el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #4F9AFE;
  }

  .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #4F9AFE;
  }

  .el-radio__input.is-disabled.is-checked .el-radio__inner {
    background-color: #FFFFFF;
  }
}

//支付历史
.payHis ::v-deep .el-drawer {
  padding: 20px;

  .title {
    padding: 0 8px 12px;
    border-bottom: 1px solid #82B6FD;
    font-size: 14px;
    font-weight: 500;
    color: #4F9AFE;
    position: relative;

    h4 {
      color: #333333;
      font-size: 22px;
      margin-right: 10px;
    }

    .el-icon-close {
      position: absolute;
      right: 0;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .record-table {
    margin-top: 20px;

    .colorStyle {
      color: #4F9AFE;
    }

    .wxPay {
      color: #FFAB57;
    }
  }
}

.companyInfo.el-form-item {
  margin-bottom: 10px;
}

.upload-demo ::v-deep{
  .el-button {
    width: 116px;
    height: 32px;
    background: rgba(79, 154, 254, 0.1);
    border: 1px solid #4F9AFE;
    color: #4F9AFE;
  }

  .el-upload-list__item{
    height: 32px;
    background: rgba(187, 187, 187, 0.1);
    border: 1px solid #BBBBBB;
    padding: 0 10px;
    line-height: inherit;
    color: #333333;
  }
}
